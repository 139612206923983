<template>
  <div>
    <Modal
      v-model="imagesModal"
      title="Image"
      footer-hide
      :mask-closable="false"
    >
      <Cropper
        :stencil-props="{
          aspectRatio: 16 / 9
        }"
        classname="cropper"
        :src="file.images"
        ref="imagesCropper"
      />

      <Upload
        class="mr-20 inline-block"
        accept="image/*"
        :before-upload="beforeUploadImages"
        action
      >
        <Button>
          Choose image
        </Button>
      </Upload>

      <Button
        :loading="uploadImagesLoading"
        :disabled="!file.images"
        @click="uploadImages"
        type="primary"
      >
        Upload
      </Button>
    </Modal>

    <div class="main sc-width">
<!--      <div class="top">-->
<!--        <router-link to="/company-profile?type=product" class="">-->
<!--          <div class="back-request">-->
<!--            &lt;!&ndash;            <div class="pic"><img src="@/assets/back.png" alt="" /></div>&ndash;&gt;-->
<!--            <div class="word">Return to List</div>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->
      <div class="content-wide">
        <div class="main-title">Add a product</div>

        <Steps class="mb-20" :current="step">
          <Step title="Basic Info"></Step>
          <Step title="Properties"></Step>
          <Step title="Specifications"></Step>
          <Step title="Contaminants"></Step>
          <Step title="Documentation"></Step>
          <Step title="Photos"></Step>
          <Step title="Analysis"></Step>
        </Steps>
        <br />

        <Form
          v-show="step === 0"
          ref="form1"
          :model="form1"
          class="product-form"
        >
          <h2 class="divide">Basic Info</h2>
          <Row :gutter="20">
            <i-col span="12">
              <FormItem
                prop="marketing_name"
                :rules="required"
                label="Marketing Name"
              >
                <Input
                  v-model="form1.marketing_name"
                  placeholder="(e.g. Curcumin 95%)"
                />
              </FormItem>
            </i-col>

            <i-col span="12">
              <FormItem prop="descriptive_name" label="Descriptive Name">
                <Input
                  v-model="form1.descriptive_name"
                  placeholder="(e.g. Tumeric Extract @ 95% Curcumin)"
                />
              </FormItem>
            </i-col>

            <i-col span="16">
              <FormItem prop="other_common_name" label="Other Common Name(s)">
                <Input
                  v-model="form1.other_common_name"
                  placeholder="(e.g. Curcumin)"
                />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem
                prop="product_type"
                :rules="required"
                label="Product Type"
              >
                <Select v-model="form1.product_type" style="text-align: left">
                  <OptionGroup label="Botanical">
                    <Option value="Botanical">Botanical</Option>
                  </OptionGroup>

                  <OptionGroup label="Non-Botanical">
                    <Option
                      v-for="item in product_type['Non-Botanical']"
                      :key="item"
                      :value="item"
                      >{{ item }}</Option
                    >
                  </OptionGroup>
                </Select>
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem
                label="Name of Factory"
                :rules="required"
                prop="factory_id"
              >
                <Select
                  @on-open-change="queryFactoryList($event)"
                  filterable
                  v-model="form1.factory_id"
                >
                  <Option
                    v-for="item in factoryList"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.factory_name }}</Option
                  >
                </Select>
                <div class="item-tips">
                  <span class="info">Can't find a factory?</span>
                  <router-link target="_blank" to="/factories/create">
                    <span class="link">Create a factory here</span>
                  </router-link>
                </div>
              </FormItem>
            </i-col>

            <i-col span="12">
              <FormItem
                label="Country of Origin"
                :rules="required"
                prop="origin_country"
              >
                <Select filterable v-model="form1.origin_country">
                  <Option
                    v-for="item in countryList"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </FormItem>
            </i-col>

            <i-col span="12">
              <FormItem
                label="Country of Manufacture"
                :rules="required"
                prop="manufacture_country"
              >
                <Select filterable v-model="form1.manufacture_country">
                  <Option
                    v-for="item in countryList"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </FormItem>
            </i-col>

            <i-col span="13">
              <FormItem prop="cas" :rules="required" label="CAS #">
                <Input v-model="form1.cas" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <div class="form-box-group">
                <div class="form-box-title form-title-xing">
                  Industry Usage Grade
                </div>
                <div class="compose">
                  <div class="checkbox-box">
                    <FormItem prop="industry_usage_grade" :rules="required">
                      <CheckboxGroup
                        class="mb-10"
                        v-model="form1.industry_usage_grade"
                      >
                        <Row :gutter="20">
                          <i-col
                            v-for="item in industry_usage_grade"
                            :key="item"
                            span="12"
                          >
                            <Checkbox :label="item" />
                          </i-col>
                        </Row>
                      </CheckboxGroup>
                      <Input
                        v-model="industry_usage_grade_temp"
                        class="mr-10 checkbox-box-input"
                      />
                      <Button
                        @click="addCheckBox('industry_usage_grade')"
                        type="primary"
                        >Add Item</Button
                      >
                    </FormItem>
                  </div>
                </div>
              </div>
            </i-col>

            <i-col span="12">
              <div class="form-box-group">
                <div class="form-box-title form-title-xing">
                  Compendial Standard
                </div>
                <div class="compose">
                  <div class="checkbox-box">
                    <FormItem prop="compendial_standard" :rules="required">
                      <CheckboxGroup
                        class="mb-10"
                        v-model="form1.compendial_standard"
                      >
                        <Row :gutter="20">
                          <i-col
                            v-for="item in compendial_standard"
                            :key="item"
                            span="12"
                          >
                            <Checkbox :label="item" />
                          </i-col>
                        </Row>
                      </CheckboxGroup>
                      <Input
                        v-model="compendial_standard_temp"
                        class="mr-10 checkbox-box-input"
                      />
                      <Button
                        @click="addCheckBox('compendial_standard')"
                        type="primary"
                        >Add Item</Button
                      >
                    </FormItem>
                  </div>
                </div>
              </div>
            </i-col>

            <i-col span="24">
              <div class="form-box-group">
                <br />
                <h2 class="divide">Warehouses</h2>
                <div
                  class="compose"
                  v-for="(item, index) in form1.warehouse"
                  :key="index"
                >
                  <Button
                    v-if="index !== 0"
                    class="mb-10"
                    type="error"
                    ghost
                    @click="removeItem('form1', 'warehouse', index)"
                  >
                    <Icon type="md-trash" />
                  </Button>

                  <Row :gutter="20">
                    <i-col span="8">
                      <FormItem :prop="`warehouse[${index}].address`">
                        <Input
                          v-model="item.address"
                          placeholder="Street Address"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="8">
                      <FormItem :prop="`warehouse[${index}].city`">
                        <Input v-model="item.city" placeholder="City" />
                      </FormItem>
                    </i-col>
                    <i-col span="8">
                      <FormItem :prop="`warehouse[${index}].state`">
                        <Input v-model="item.state" placeholder="State" />
                      </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem :prop="`warehouse[${index}].country`">
                        <Select
                          filterable
                          v-model="item.country"
                          placeholder="Select a country"
                        >
                          <Option
                            v-for="item in countryList"
                            :value="item.id"
                            :key="item.id"
                            >{{ item.name }}</Option
                          >
                        </Select>
                      </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem :prop="`warehouse[${index}].inventory_count`">
                        <Input
                          v-model="item.inventory_count"
                          placeholder="Enter the average amount of inventory..."
                        />
                      </FormItem>
                    </i-col>
                  </Row>
                </div>
              </div>
            </i-col>

            <i-col span="24">
              <FormItem>
                <Button
                  type="primary"
                  ghost
                  @click="
                    addItem('form1', 'warehouse', {
                      city: null,
                      state: null,
                      country: null,
                      inventory_count: null,
                      address: null
                    })
                  "
                  >Add Item</Button
                >
              </FormItem>
            </i-col>

            <i-col span="12">
              <FormItem
                prop="sort"
                :rules="required"
                label="Sort (enter '1' if you want this product to show up first in the list.)"
              >
                <Input type="number" v-model="form1.sort" />
              </FormItem>
            </i-col>
          </Row>
          <br />

          <template v-if="form1.product_type === 'Botanical'">
            <h2 class="divide">Additional Details for Botanicals</h2>

            <Row :gutter="20">
              <i-col span="8">
                <FormItem
                  prop="botanicals_additional_details.botanical_name"
                  label="Botanical Name"
                  :rules="required"
                >
                  <Input
                    v-model="form1.botanicals_additional_details.botanical_name"
                  />
                </FormItem>
              </i-col>
              <i-col span="8">
                <FormItem
                  prop="botanicals_additional_details.plant_part_used"
                  label="Plant Part Used"
                  :rules="required"
                >
                  <Input
                    v-model="
                      form1.botanicals_additional_details.plant_part_used
                    "
                  />
                </FormItem>
              </i-col>

              <i-col span="8">
                <FormItem
                  prop="botanicals_additional_details.botanical_extract_ratio"
                  label="Botanical Extract Ratio"
                >
                  <Input
                    placeholder="(e.g. 1:1)"
                    v-model="
                      form1.botanicals_additional_details
                        .botanical_extract_ratio
                    "
                  />
                </FormItem>
              </i-col>

              <i-col span="8">
                <FormItem
                  prop="botanicals_additional_details.of_active_component"
                  label="% of Active Component(s)"
                >
                  <Input
                    v-model="
                      form1.botanicals_additional_details.of_active_component
                    "
                  />
                </FormItem>
              </i-col>
              <i-col span="8">
                <FormItem
                  prop="botanicals_additional_details.test_method_of_active_component"
                  label="Test Method For Active Component(s)"
                >
                  <Input
                    v-model="
                      form1.botanicals_additional_details
                        .test_method_of_active_component
                    "
                  />
                </FormItem>
              </i-col>
            </Row>
          </template>

          <div class="form-btn">
            <div
              class="save-draft  mt-20"
              @click="saveDraft"
              :loading="draftLoading"
            >
              Save As Draft
            </div>
            <div class="validate-error" v-if="showValidateError">
              You still have something to fill out
            </div>
            <div class="continue  mt-20" ghost @click="form1Validate">
              Continue to Step 2
            </div>
          </div>
        </Form>

        <Form
          v-show="step === 1"
          ref="form2"
          :model="form2"
          class="product-form"
        >
          <h2 class="divide">Properties</h2>
          <Row :gutter="20">
            <i-col span="24">
              <div class="form-box-group">
                <div class="form-box-title">
                  <span
                    >Product Description -
                    <span style="color:red;"
                      >Must be more than 155 characters</span
                    ></span
                  >
                </div>
                <div class="compose">
                  <FormItem prop="description" :rules="required">
                    <span class="des-length">{{
                      form2.description
                        ? form2.description.replace(/\s/g, "").length
                        : 0
                    }}</span>
                    <Input
                      type="textarea"
                      :rows="4"
                      v-model="form2.description"
                      placeholder="Enter description here..."
                    />
                  </FormItem>
                </div>
              </div>
            </i-col>

            <i-col span="24">
              <div class="form-box-group">
                <div class="form-box-title">
                  Appearance
                </div>
                <div class="compose">
                  <Row :gutter="20">
                    <i-col span="8">
                      <FormItem prop="appearance.color">
                        <Input
                          v-model="form2.appearance.color"
                          placeholder="Color"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="8">
                      <FormItem prop="appearance.texture">
                        <Input
                          v-model="form2.appearance.texture"
                          placeholder="Texture (e.g. free-flowing, granules, crystalline)"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="8">
                      <FormItem prop="appearance.matter_state">
                        <Input
                          v-model="form2.appearance.matter_state"
                          placeholder="State of Matter... (e.g. powder, liquid, oil softgel)"
                        />
                      </FormItem>
                    </i-col>
                  </Row>
                </div>
              </div>
            </i-col>

            <i-col span="24">
              <div class="form-box-group">
                <div class="form-box-title">
                  Solubility
                </div>
                <div
                  class="compose"
                  v-for="(item, index) in form2.solubility"
                  :key="index"
                >
                  <Button
                    v-if="index !== 0"
                    class="mb-10"
                    type="error"
                    ghost
                    @click="removeItem('form2', 'solubility', index)"
                  >
                    <Icon type="md-trash" />
                  </Button>

                  <Row :gutter="20">
                    <i-col span="12">
                      <FormItem :prop="`solubility[${index}].claim`">
                        <Input v-model="item.claim" placeholder="Claim" />
                      </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem :prop="`solubility[${index}].solvent_medium`">
                        <Input
                          v-model="item.solvent_medium"
                          placeholder="Solvent Medium"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem
                        :prop="`solubility[${index}].product_solute_amount`"
                      >
                        <Input
                          v-model="item.product_solute_amount"
                          placeholder="Amount of Solute (product)"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem
                        :prop="`solubility[${index}].medium_solute_amount`"
                      >
                        <Input
                          v-model="item.medium_solute_amount"
                          placeholder="Amount of Solvent (medium)"
                        />
                      </FormItem>
                    </i-col>
                  </Row>
                </div>
              </div>
            </i-col>

            <i-col span="24">
              <FormItem>
                <Button
                  type="primary"
                  ghost
                  @click="
                    addItem('form2', 'solubility', {
                      claim: null,
                      solvent_medium: null,
                      product_solute_amount: null,
                      medium_solute_amount: null
                    })
                  "
                  >Add Item</Button
                >
              </FormItem>
            </i-col>

            <i-col span="24">
              <div class="form-box-group">
                <div class="form-box-title">
                  <div>Percent Composition - Must Add Up To 100%</div>
                  <div>Total Composition: {{ percent }}%</div>
                </div>
                <div
                  class="compose"
                  v-for="(item, index) in form2.composition_percent"
                  :key="index"
                >
                  <Button
                    v-if="index !== 0"
                    class="mb-10"
                    type="error"
                    ghost
                    @click="removeItem('form2', 'composition_percent', index)"
                    ><Icon type="md-trash"
                  /></Button>
                  <Row :gutter="20">
                    <i-col span="12">
                      <FormItem
                        :prop="`composition_percent[${index}].ingredient`"
                      >
                        <Input
                          v-model="item.ingredient"
                          placeholder="Ingredient Input"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem
                        :prop="`composition_percent[${index}].composition`"
                      >
                        <InputNumber
                          :style="{ width: '100%' }"
                          v-model="item.composition"
                          placeholder="% Composition"
                          :formatter="value => `%${value}`"
                          :parser="value => value.replace('%', '')"
                        />
                      </FormItem>
                    </i-col>
                  </Row>
                </div>
              </div>
            </i-col>

            <i-col span="24">
              <FormItem>
                <Button
                  type="primary"
                  ghost
                  @click="
                    addItem('form2', 'composition_percent', {
                      ingredient: null,
                      composition: null
                    })
                  "
                  >Add Item</Button
                >
              </FormItem>
            </i-col>

            <i-col span="24">
              <div class="form-box-group">
                <div class="form-box-title">
                  Particle Size
                </div>
                <div
                  class="compose"
                  v-for="(item, index) in form2.particle_size"
                  :key="index"
                >
                  <Button
                    v-if="index !== 0"
                    class="mb-10"
                    type="error"
                    ghost
                    @click="removeItem('form2', 'particle_size', index)"
                    ><Icon type="md-trash"
                  /></Button>
                  <Row :gutter="20">
                    <i-col span="8">
                      <FormItem
                        :prop="`particle_size[${index}].mesh_screen_size`"
                      >
                        <Input
                          v-model="item.mesh_screen_size"
                          placeholder="Mesh Screen Size"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="8">
                      <FormItem :prop="`particle_size[${index}].analysis`">
                        <Input v-model="item.analysis" placeholder="Analysis" />
                      </FormItem>
                    </i-col>
                    <i-col span="8">
                      <FormItem :prop="`particle_size[${index}].yield`">
                        <InputNumber
                          :style="{ width: '100%' }"
                          v-model="item.yield"
                          placeholder="% Yield"
                          :formatter="value => `%${value}`"
                          :parser="value => value.replace('%', '')"
                        />
                      </FormItem>
                    </i-col>
                  </Row>
                </div>
              </div>
            </i-col>

            <i-col span="24">
              <FormItem>
                <Button
                  type="primary"
                  ghost
                  @click="
                    addItem('form2', 'particle_size', {
                      mesh_screen_size: null,
                      analysis: null,
                      yield: null
                    })
                  "
                  >Add Item</Button
                >
              </FormItem>
            </i-col>

            <i-col span="24">
              <div class="form-box-group">
                <div class="form-box-title">
                  Storage And Handling
                </div>
                <div class="compose">
                  <Row :gutter="20" type="flex" align="bottom">
                    <i-col span="12">
                      <FormItem prop="storage_and_handling.shelf_life">
                        <Input
                          v-model="form2.storage_and_handling.shelf_life"
                          placeholder="Shelf Life"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="12" />
                    <i-col span="12">
                      <FormItem
                        prop="storage_and_handling.recommended_storage_conditions"
                      >
                        <Input
                          type="textarea"
                          :rows="4"
                          v-model="
                            form2.storage_and_handling
                              .recommended_storage_conditions
                          "
                          placeholder="Recommended Storage Conditions"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="12">
                      <FormItem
                        prop="storage_and_handling.packaging_configurations"
                      >
                        <Input
                          type="textarea"
                          :rows="4"
                          v-model="
                            form2.storage_and_handling.packaging_configurations
                          "
                          placeholder="Packaging Configurations (include netweight and container closure system type)"
                        />
                      </FormItem>
                    </i-col>
                  </Row>
                </div>
              </div>
            </i-col>

            <i-col span="24">
              <div class="form-box-group">
                <div class="form-box-title">
                  Common Industries Served
                </div>
                <div class="compose">
                  <div class="checkbox-box">
                    <Row :gutter="20" type="flex" align="bottom">
                      <FormItem prop="common_industries_served">
                        <CheckboxGroup
                          class="mb-10"
                          v-model="form2.common_industries_served"
                        >
                          <Row :gutter="20">
                            <i-col
                              v-for="item in common_industries_served"
                              :key="item"
                              span="6"
                            >
                              <Checkbox :label="item" />
                            </i-col>
                          </Row>
                        </CheckboxGroup>

                        <Input
                          v-model="common_industries_served_temp"
                          class="mr-10 checkbox-box-input"
                        />
                        <Button
                          @click="addCheckBox('common_industries_served')"
                          type="primary"
                          >Add Item</Button
                        >
                      </FormItem>
                    </Row>
                  </div>
                </div>
              </div>
            </i-col>
          </Row>

          <div class="form-btn">
            <div
              class="save-draft  mt-20"
              @click="saveDraft"
              :loading="draftLoading"
            >
              Save As Draft
            </div>
            <div class="btns">
              <div class="back  mt-20 mr-10" ghost @click="step--">Go back</div>
              <div class="continue mt-20" ghost @click="form2Validate">
                Continue to Step 3
              </div>
            </div>
          </div>
        </Form>

        <Form
          v-show="step === 2"
          ref="form3"
          :model="form3"
          class="product-form"
        >
          <h2 class="divide">Specifications</h2>

          <Row :gutter="20">
            <i-col span="24">
              <div class="form-box-group">
                <div class="form-box-title">
                  Assay Claims
                </div>
                <div
                  class="compose"
                  v-for="(item, index) in form3.assay_claims"
                  :key="index"
                >
                  <Button
                    v-if="index !== 0"
                    class="mb-10"
                    type="error"
                    ghost
                    @click="removeItem('form3', 'assay_claims', index)"
                    ><Icon type="md-trash"
                  /></Button>
                  <Row :gutter="20">
                    <i-col span="8">
                      <FormItem :prop="`assay_claims[${index}].assay_analyte`">
                        <Input
                          v-model="item.assay_analyte"
                          placeholder="Assay Analyte"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="8">
                      <FormItem :prop="`assay_claims[${index}].specification`">
                        <Input
                          v-model="item.specification"
                          placeholder="Specification"
                        />
                      </FormItem>
                    </i-col>
                    <i-col span="8">
                      <FormItem :prop="`assay_claims[${index}].test_method`">
                        <Input
                          v-model="item.test_method"
                          placeholder="Test Method"
                        />
                      </FormItem>
                    </i-col>
                  </Row>
                </div>
              </div>
            </i-col>

            <i-col span="24">
              <FormItem>
                <Button
                  type="primary"
                  ghost
                  @click="
                    addItem('form3', 'assay_claims', {
                      assay_analyte: null,
                      specification: null,
                      test_method: null
                    })
                  "
                  >Add Item</Button
                >
              </FormItem>
            </i-col>

            <i-col span="8">
              <FormItem prop="chemical_formula" label="Chemical Formula">
                <Input v-model="form3.chemical_formula" />
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem prop="molecular_weight" label="Molecular Weight">
                <Input v-model="form3.molecular_weight" />
              </FormItem>
            </i-col>
            <i-col span="8">
              <FormItem prop="processing_method" label="Processing Method">
                <Input
                  placeholder="(e.g. synthetic, fermentation, milling)"
                  v-model="form3.processing_method"
                />
              </FormItem>
            </i-col>

            <i-col span="12">
              <FormItem prop="starting_material" label="Starting Material(s)">
                <Input
                  type="textarea"
                  :rows="4"
                  v-model="form3.starting_material"
                />
              </FormItem>
            </i-col>

            <i-col span="12">
              <FormItem
                prop="solvents_used_in_production"
                label="Solvents Used In Production"
              >
                <Input
                  type="textarea"
                  :rows="4"
                  v-model="form3.solvents_used_in_production"
                />
              </FormItem>
            </i-col>
          </Row>
          <div class="form-btn">
            <div
              class="save-draft mt-20"
              @click="saveDraft"
              :loading="draftLoading"
            >
              Save As Draft
            </div>
            <div class="btns">
              <div class="back  mt-20 mr-10" ghost @click="step--">Go back</div>
              <div class="continue mt-20" ghost @click="form3Validate">
                Continue to Step 4
              </div>
            </div>
          </div>
        </Form>

        <Form
          v-show="step === 3"
          ref="form4"
          :model="form4"
          class="product-form"
        >
          <h2 class="divide">Contaminants</h2>

          <!-- <Row :gutter="20">
          <i-col span="12">

          </i-col>

          <i-col span="12">

          </i-col>
        </Row> -->

          <div class="form-box-group">
            <div class="form-box-title">
              Breakdown of Microbial Contaminants
            </div>
            <div
              class="compose"
              v-for="(item, index) in form4.microbial_contaminants_breakdown"
              :key="index"
            >
              <Button
                v-if="index !== 0"
                type="error"
                ghost
                class="mb-10"
                @click="
                  removeItem('form4', 'microbial_contaminants_breakdown', index)
                "
                ><Icon type="md-trash"
              /></Button>
              <Row :gutter="20">
                <i-col span="6">
                  <FormItem
                    :prop="`microbial_contaminants_breakdown[${index}].name`"
                  >
                    <Input v-model="item.name" placeholder="Enter name..." />
                  </FormItem>
                </i-col>
                <i-col span="6">
                  <FormItem
                    :prop="
                      `microbial_contaminants_breakdown[${index}].microbial_count`
                    "
                  >
                    <InputNumber
                      :style="{ width: '100%' }"
                      v-model="item.microbial_count"
                      placeholder="Enter microbial count..."
                    />
                  </FormItem>
                </i-col>
                <i-col span="6">
                  <FormItem
                    :prop="
                      `microbial_contaminants_breakdown[${index}].specification`
                    "
                  >
                    <Input
                      v-model="item.specification"
                      placeholder="Add specification..."
                    />
                  </FormItem>
                </i-col>
                <i-col span="6">
                  <FormItem
                    :prop="
                      `microbial_contaminants_breakdown[${index}].test_method`
                    "
                  >
                    <Input
                      v-model="item.test_method"
                      placeholder="Enter test method..."
                    />
                  </FormItem>
                </i-col>
              </Row>
            </div>
          </div>
          <FormItem>
            <Button
              type="primary"
              ghost
              @click="
                addItem('form4', 'microbial_contaminants_breakdown', {
                  name: null,
                  microbial_count: null,
                  specification: null,
                  test_method: null
                })
              "
              >Add Item</Button
            >
          </FormItem>

          <div class="form-box-group">
            <div class="form-box-title">
              Breakdown of Heavy Metals
            </div>
            <div
              class="compose"
              v-for="(item, index) in form4.heavy_metals_breakdown"
              :key="index"
            >
              <Button
                v-if="index !== 0"
                class="mb-10"
                type="error"
                ghost
                @click="removeItem('form4', 'heavy_metals_breakdown', index)"
                ><Icon type="md-trash"
              /></Button>
              <Row :gutter="20">
                <i-col span="6">
                  <FormItem :prop="`heavy_metals_breakdown[${index}].name`">
                    <Input v-model="item.name" placeholder="Enter name..." />
                  </FormItem>
                </i-col>
                <i-col span="6">
                  <FormItem
                    :prop="`heavy_metals_breakdown[${index}].heavy_metal_count`"
                  >
                    <InputNumber
                      :precision="2"
                      :style="{ width: '100%' }"
                      v-model="item.heavy_metal_count"
                      placeholder="Enter heavy metal count..."
                    />
                  </FormItem>
                </i-col>
                <i-col span="6">
                  <FormItem
                    :prop="`heavy_metals_breakdown[${index}].specification`"
                  >
                    <Input
                      v-model="item.specification"
                      placeholder="Add specification..."
                    />
                  </FormItem>
                </i-col>
                <i-col span="6">
                  <FormItem
                    :prop="`heavy_metals_breakdown[${index}].test_method`"
                  >
                    <Input
                      v-model="item.test_method"
                      placeholder="Enter test method..."
                    />
                  </FormItem>
                </i-col>
              </Row>
            </div>
          </div>
          <FormItem>
            <Button
              type="primary"
              ghost
              @click="
                addItem('form4', 'heavy_metals_breakdown', {
                  heavy_metal_count: null,
                  specification: null,
                  test_method: null
                })
              "
              >Add Item</Button
            >
          </FormItem>

          <div class="form-btn">
            <div
              class="save-draft mt-20"
              @click="saveDraft"
              :loading="draftLoading"
            >
              Save As Draft
            </div>
            <div class="btns">
              <div class="back  mt-20 mr-10" ghost @click="step--">Go back</div>
              <div class="continue mt-20" ghost @click="form4Validate">
                Continue to Step 5
              </div>
            </div>
          </div>
        </Form>

        <Form
          v-show="step === 4"
          ref="form5"
          :model="form5"
          class="product-form"
        >
          <h2 class="divide">Documentation</h2>

          <Row :gutter="20">
            <i-col span="12">
              <FormItem label="Documents" prop="document">
                <Select
                  filterable
                  multiple
                  v-model="form5.document"
                  :max-tag-count="3"
                  @on-open-change="queryDocumentList($event)"
                >
                  <Option
                    v-for="item in documentList"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
                <div class="item-tips">
                  <span class="info">Can't find a document?</span>
                  <router-link target="_blank" to="/documents/create">
                    <span class="link">Upload a document here</span>
                  </router-link>
                </div>
              </FormItem>
            </i-col>
          </Row>

          <div class="form-btn">
            <div
              class="save-draft mt-20"
              @click="saveDraft"
              :loading="draftLoading"
            >
              Save As Draft
            </div>
            <div class="btns">
              <div class="back  mt-20 mr-10" ghost @click="step--">Go back</div>
              <div class="continue mt-20" ghost @click="form5Validate">
                Continue to Step 6
              </div>
            </div>
          </div>
        </Form>

        <Form
          v-show="step === 5"
          ref="form6"
          :model="form6"
          class="product-form"
        >
          <h2 class="divide">Photos</h2>
          <div class="library-images">
            <div class="library-images-title">
              Please choose a product image from our library
            </div>
            <div class="library-images-items">
              <div
                class="library-images-item"
                @click="chooseImage(index, item)"
                v-for="(item, index) in libraryImages"
                :key="index"
              >
                <img :src="item" alt="" />
                <div
                  :class="[
                    'mask',
                    { 'mask-active': chooseImageIndex == index }
                  ]"
                >
                  <div class="mask-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#F57F45" />
                      <path
                        d="M9.9375 16.7142L5.8125 12.5892L6.99083 11.4109L9.93875 14.3555L9.9375 14.3567L17.0083 7.28589L18.1867 8.46422L11.1158 15.5359L9.93833 16.7134L9.9375 16.7142Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="library-or">
              <div class="library-or-line"></div>
              <div class="library-or-word">or</div>
              <div class="library-or-line"></div>
            </div>
          </div>

          <Alert show-icon>
            <template slot="desc"
              >Tip: We recommend that you upload a photo with a minimum
              dimension of 400 x 225.</template
            >
          </Alert>
          <FormItem label="Upload your own " prop="Upload your own image">
            <div class="images-box">
              <div
                class="item"
                v-for="(item, index) in form6.images"
                :key="item"
              >
                <img alt="" class="image" :src="item" />
                <div class="remove" @click="removeImage(index)">
                  Remove
                </div>
              </div>

              <div class="add" @click="imagesModal = true">
                <div class="tips">
                  <Icon type="ios-cloud-upload" size="30"></Icon>
                  <div>Choose File</div>
                </div>
              </div>
            </div>
          </FormItem>
          <FormItem label="Video" prop="video">
            <div
              v-if="!(file.video || form6.video)"
              class="upload"
              @click="$refs.file.click()"
            >
              <div class="upload-inner">
                <input
                  accept="video/*"
                  @change="handleUpload"
                  v-show="false"
                  type="file"
                  ref="file"
                />
                <Icon class="icon" type="ios-cloud-upload" size="52"></Icon>
                <p>Click here to upload</p>
              </div>
            </div>
            <div class="upload" v-else>
              <div v-if="uploadVideoLoading">
                Uploading...
              </div>

              <div v-else>
                <div v-if="form6.video">
                  <video
                    controls
                    v-if="form6.video"
                    style="display:block;width:500px;"
                    :src="form6.video"
                  />
                  <Button class="mt-10" @click="removeFile"
                    >Choose Again</Button
                  >
                </div>
              </div>
            </div>
          </FormItem>
          <div class="form-btn">
            <div
              class="save-draft mt-20"
              @click="saveDraft"
              :loading="draftLoading"
            >
              Save As Draft
            </div>
            <div class="btns">
              <div class="back  mt-20 mr-10" ghost @click="step--">Go back</div>
              <div class="continue mt-20" ghost @click="form6Validate">
                Continue to Step 7
              </div>
            </div>
          </div>
        </Form>

        <Form
          v-show="step === 6"
          ref="form6"
          :model="form7"
          class="product-form"
        >
          <h2 class="divide">Analysis</h2>

          <Row :gutter="20">
            <i-col span="12">
              <FormItem label="Current Price ($)">
                <Input v-model="form7.price" />
              </FormItem>
            </i-col>

            <i-col span="12">
              <FormItem label="Uom">
                <Select v-model="form7.uom">
                  <Option v-for="uo in uomLists" :value="uo" :key="uo">{{
                    uo
                  }}</Option>
                  <Option value="Other">Other</Option>
                </Select>
                <Input
                  v-if="form7.uom === 'Other'"
                  v-model="uom2"
                  placeholder="Enter your UOM"
                />
              </FormItem>
            </i-col>
          </Row>
          <div class="form-btn last-step">
            <div class="btns">
              <div class="back  mt-20 mr-10" ghost @click="step--">Go back</div>
              <div
                class="add-to-catalog mt-20"
                ghost
                @click="form7Validate"
                :loading="createLoading"
              >
                Add Product to Catalog
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
    <div class="form-foot"></div>
  </div>
</template>

<script>
import { dataURLtoBlob, blobToFile } from "@/utils/helper";
import { uniq, trim } from "lodash";
import NP from "number-precision";

import S3 from "aws-s3";

const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});
import api from "@/api";
const {
  _queryProductRelatedInfo,
  _country,
  _queryFactoryList,
  _queryDocumentList,
  _createProduct,
  _createDraft,
  _queryProduct,
  _editDraft,
  _publishDraft
} = api;
import { Cropper } from "vue-advanced-cropper";

export default {
  components: {
    Cropper
  },
  created() {
    const draft = this.$route.query.draft;
    if (draft) {
      // this.queryProductLoading = true;
      _queryProduct({ id: draft })
        .then(response => {
          const { data } = response;

          this.form1.marketing_name = data.marketing_name;
          this.form1.descriptive_name = data.descriptive_name;
          this.form1.other_common_name = data.other_common_name;
          this.form1.product_type = data.product_type;
          this.form1.factory_id = data.factory_id;
          this.form1.origin_country = data.origin_country;
          this.form1.manufacture_country = data.manufacture_country;
          this.form1.cas = data.cas;
          this.form1.sort = data.sort || 10;
          if (data.industry_usage_grade) {
            this.industry_usage_grade = uniq([
              ...this.industry_usage_grade,
              ...data.industry_usage_grade
            ]);
            this.form1.industry_usage_grade = data.industry_usage_grade;
          }
          if (data.compendial_standard) {
            this.compendial_standard = uniq([
              ...this.compendial_standard,
              ...data.compendial_standard
            ]);
            this.form1.compendial_standard = data.compendial_standard;
          }
          if (data.warehouses && data.warehouses.length > 0) {
            this.form1.warehouse = data.warehouses;
          }
          if (data.botanicals_additional_details) {
            this.form1.botanicals_additional_details =
              data.botanicals_additional_details;
          }

          // from2
          this.form2.description = data.property.description;
          if (data.property.appearance) {
            this.form2.appearance = data.property.appearance;
          }
          if (data.property.solubility) {
            this.form2.solubility = data.property.solubility;
          }

          if (data.property.composition_percent) {
            this.form2.composition_percent = data.property.composition_percent.map(
              item => {
                item.composition = Number(item.composition);
                return item;
              }
            );
          }

          if (data.property.particle_size) {
            this.form2.particle_size = data.property.particle_size.map(item => {
              item.yield = Number(item.yield);
              return item;
            });
          }

          if (data.property.storage_and_handling) {
            this.form2.storage_and_handling =
              data.property.storage_and_handling;
          }

          if (data.property.common_industries_served) {
            this.common_industries_served = uniq([
              ...this.common_industries_served,
              ...data.property.common_industries_served
            ]);
            this.form2.common_industries_served =
              data.property.common_industries_served;
          }

          // form3
          if (data.specification.assay_claims) {
            this.form3.assay_claims = data.specification.assay_claims;
          }

          this.form3.chemical_formula = data.specification.chemical_formula;
          this.form3.molecular_weight = data.specification.molecular_weight;
          this.form3.processing_method = data.specification.processing_method;
          this.form3.starting_material = data.specification.starting_material;
          if (data.specification.solvents_used_in_production) {
            this.form3.solvents_used_in_production =
              data.specification.solvents_used_in_production;
          }

          // form4
          if (data.contaminant.microbial_contaminants_breakdown) {
            this.form4.microbial_contaminants_breakdown = data.contaminant.microbial_contaminants_breakdown.map(
              item => {
                item.microbial_count = Number(item.microbial_count);
                return item;
              }
            );
          }

          if (data.contaminant.heavy_metals_breakdown) {
            this.form4.heavy_metals_breakdown = data.contaminant.heavy_metals_breakdown.map(
              item => {
                item.heavy_metal_count = Number(item.heavy_metal_count);
                return item;
              }
            );
          }

          //form5
          if (data.documents) {
            this.form5.document = data.documents.map(item => item.id);
          }

          // form6
          if (data.video) {
            this.form6.video = data.video[0] && data.video[0].url;
          }

          if (data.images) {
            this.form6.images = data.images.map(img => img.url);
          }

          // this.form7.market_trend = data.market_trend;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          // this.queryProductLoading = false;
        });
    }

    this.queryFactoryList();

    this.queryDocumentList();

    _country()
      .then(response => {
        const { data } = response;
        this.countryList = data;
      })
      .catch(error => {
        console.log(error);
      });

    _queryProductRelatedInfo()
      .then(response => {
        const {
          data: { product_type }
        } = response;
        // console.log(response);

        this.product_type = product_type;
      })
      .catch(error => {
        console.log(error);
      });
  },
  mounted() {
    let _this = this;
    console.log(_this.$route.fullPath);
    window.onbeforeunload = function(e) {
      if (_this.$route.fullPath == "/products/create") {
        e = e || window.event;
        // 兼容IE8和Firefox 4之前的版本
        if (e) {
          e.returnValue = "关闭提示";
        }
        // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return "关闭提示";
      } else {
        window.onbeforeunload = null;
      }
    };
  },
  computed: {
    percent() {
      return this.form2.composition_percent.reduce((all, next) => {
        return NP.plus(all, Number(next.composition));
      }, 0);
    }
  },

  data() {
    return {
      industry_usage_grade_temp: "",
      compendial_standard_temp: "",
      common_industries_served_temp: "",

      imagesModal: false,
      uploadImagesLoading: false,
      uploadVideoLoading: false,

      file: {
        video: null,
        images: null
      },

      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],

      product_type: [],
      factoryList: [],
      countryList: [],
      documentList: [],

      industry_usage_grade: [
        "Food Grade",
        "Feed Grade",
        "Industrial Grade",
        "Cosmetic Grade"
      ],

      compendial_standard: ["USP", "FCC", "JP", "CP"],

      common_industries_served: [
        "Food & Beverage",
        "Dietary Supplements",
        "Personal Care",
        "Cosmeceuticals",
        "Animal Nutrition",
        "Pharmaceuticals"
      ],

      createLoading: false,
      draftLoading: false,

      step: 0,
      showValidateError: false,
      form7: {
        price: 0,
        uom: "Kg"
      },
      uomLists: ["Kg", "Lbs", "Oz", "G"],
      uom2: "",

      form6: {
        images: [],
        video: null,
        libraryImages: null
      },

      form5: {
        document: []
      },

      form4: {
        microbial_contaminants_breakdown: [
          {
            name: null,
            microbial_count: null,
            specification: null,
            test_method: null
          }
        ],
        heavy_metals_breakdown: [
          {
            heavy_metal_count: null,
            specification: null,
            test_method: null
          }
        ]
      },

      form3: {
        assay_claims: [
          {
            assay_analyte: null,
            specification: null,
            test_method: null
          }
        ],
        chemical_formula: null,
        molecular_weight: null,
        processing_method: null,
        starting_material: null,
        solvents_used_in_production: null
      },

      form2: {
        description: null,
        appearance: {
          color: null,
          texture: null,
          matter_state: null
        },
        solubility: [
          {
            claim: null,
            solvent_medium: null,
            product_solute_amount: null,
            medium_solute_amount: null
          }
        ],
        composition_percent: [
          {
            ingredient: null,
            composition: null
          }
        ],
        particle_size: [
          {
            mesh_screen_size: null,
            analysis: null,
            yield: null
          }
        ],
        storage_and_handling: {
          shelf_life: null,
          recommended_storage_conditions: null,
          packaging_configurations: null
        },
        common_industries_served: []
      },

      form1: {
        marketing_name: null,
        descriptive_name: null,
        other_common_name: null,
        product_type: null,
        factory_id: null,
        origin_country: null,
        manufacture_country: null,
        cas: null,
        sort: 10,
        industry_usage_grade: [],
        compendial_standard: [],

        warehouse: [
          {
            city: null,
            state: null,
            country: null,
            inventory_count: null,
            address: null
          }
        ],

        botanicals_additional_details: {
          botanical_name: null,
          plant_part_used: null,
          botanical_extract_ratio: null,
          of_active_component: null,
          test_method_of_active_component: null
        }
      },
      libraryImages: [
        "https://connexima.s3-us-west-1.amazonaws.com/documents/mY8WwFhn8xBxEK9VYr87BY.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/tVrTv4YAXrP4SHo7qUCGWF.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/wRHLB68DC3JCeHHyA1bHGJ.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/vmQH6Y7hQpkAcCV8NZEpFN.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/4xerdLmorbFBUjST6exRiG.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/nQAUsDGyfkd3SjgVSLTRv9.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/52JK1AGjCPHiv9nJY7ukap.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/hqhSUP6UnaXPUqfnThvumu.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/pgYFP2s1GYSEoYAkwhdSYr.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/sknXMPX6G4dCWinsX3XJdF.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/x3dedzyEwpYupqtePtJHJ3.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/d74tAGEwork9AD3dboacUB.jpeg",
        "https://connexima.s3-us-west-1.amazonaws.com/documents/K96x4pNzRMzgthQ3b5RaA.jpeg"
      ],
      chooseImageIndex: -1
    };
  },

  methods: {
    chooseImage(index, item) {
      if (index == this.chooseImageIndex) {
        this.chooseImageIndex = -1;
        this.form6.libraryImages = null;
        return;
      }
      this.form6.libraryImages = item;
      this.chooseImageIndex = index;
    },
    saveDraft() {
      const draft = this.$route.query.draft;
      let _save;
      if (draft) {
        _save = _editDraft;
      } else {
        _save = _createDraft;
      }

      this.draftLoading = true;
      const form1 = JSON.parse(JSON.stringify(this.form1));
      const form2 = JSON.parse(JSON.stringify(this.form2));
      const form3 = JSON.parse(JSON.stringify(this.form3));
      const form4 = JSON.parse(JSON.stringify(this.form4));
      const form5 = JSON.parse(JSON.stringify(this.form5));
      const form6 = JSON.parse(JSON.stringify(this.form6));
      const form7 = JSON.parse(JSON.stringify(this.form7));

      if (!form6.video) {
        delete form6.video;
      }

      if (form1.product_type !== "Botanical") {
        delete form1.botanicals_additional_details;
      }

      _save({
        id: draft,
        ...form1,
        ...form2,
        ...form3,
        ...form4,
        ...form5,
        ...form6,
        ...form7
      })
        .then(() => {
          this.$Message.success("success");
          this.$router.push({
            path: "/company-profile",
            query: { type: "product" }
          });
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.draftLoading = false;
        });
    },
    queryDocumentList(flag) {
      if (flag === false) {
        return;
      }
      _queryDocumentList({
        limit: 999,
        type: "Product Documents,Product Certification"
      })
        .then(response => {
          const {
            data: { data }
          } = response;

          this.documentList = data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    queryFactoryList(flag) {
      if (flag === false) {
        return;
      }
      _queryFactoryList({
        limit: 999
      })
        .then(response => {
          const {
            data: { data }
          } = response;

          this.factoryList = data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    addCheckBox(key) {
      const value = trim(this[`${key}_temp`]);
      if (value) {
        const arr = uniq([...this[key], value]);
        this[key] = arr;
      }
    },

    beforeUploadImages(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.file.images = e.target.result;
      };
      reader.readAsDataURL(file);
      return false;
    },

    uploadImages() {
      const { canvas } = this.$refs.imagesCropper.getResult();
      const base64Data = canvas.toDataURL();
      const blob = dataURLtoBlob(base64Data);
      const file = blobToFile(blob);

      this.uploadImagesLoading = true;
      uploader
        .uploadFile(file)
        .then(data => {
          this.form6.images.push(data.location);
          this.imagesModal = false;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uploadImagesLoading = false;
        });
    },

    removeImage(index) {
      this.form6.images.splice(index, 1);
    },

    removeFile() {
      this.file.video = null;
      this.form6.video = "";
    },

    handleUpload(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          this.file.video = file;
          this.uploadVideo();
        }
      }
    },

    uploadVideo() {
      this.uploadVideoLoading = true;
      uploader
        .uploadFile(this.file.video)
        .then(data => {
          this.form6.video = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uploadVideoLoading = false;
        });
    },

    addItem(form, name, obj) {
      this[form][name].push(obj);
    },
    removeItem(form, name, index) {
      this[form][name].splice(index, 1);
    },
    form7Validate() {
      const draft = this.$route.query.draft;
      let _save;
      if (draft) {
        _save = _publishDraft;
      } else {
        _save = _createProduct;
      }

      this.createLoading = true;
      const form1 = JSON.parse(JSON.stringify(this.form1));
      const form2 = JSON.parse(JSON.stringify(this.form2));
      const form3 = JSON.parse(JSON.stringify(this.form3));
      const form4 = JSON.parse(JSON.stringify(this.form4));
      const form5 = JSON.parse(JSON.stringify(this.form5));
      const form6 = JSON.parse(JSON.stringify(this.form6));
      const form7 = JSON.parse(JSON.stringify(this.form7));

      if (!form6.video) {
        delete form6.video;
      }

      if (form1.product_type !== "Botanical") {
        delete form1.botanicals_additional_details;
      }

      _save({
        id: draft,
        ...form1,
        ...form2,
        ...form3,
        ...form4,
        ...form5,
        ...form6,
        ...form7
      })
        .then(() => {
          this.$Message.success("success");
          this.$router.push({
            path: "/company-profile",
            query: { type: "product" }
          });
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    form6Validate() {
      if (this.form6.images.length < 1 && this.form6.libraryImages == null) {
        this.$Message.warning("Please choose or upload product image!");
        return;
      } else {
        this.step++;
      }
    },
    form5Validate() {
      this.$refs.form5.validate(valid => {
        if (valid) {
          this.step++;
        }
      });
    },
    form4Validate() {
      this.$refs.form4.validate(valid => {
        if (valid) {
          this.step++;
        }
      });
    },
    form3Validate() {
      this.$refs.form3.validate(valid => {
        if (valid) {
          this.step++;
        } else {
          this.$Message.error("You still have something to fill out");
        }
      });
    },
    form2Validate() {
      this.$refs.form2.validate(valid => {
        if (valid) {
          if (this.form2.description) {
            const lenDesc = this.form2.description.length;
            // const lenWithSpace = this.form2.description.length;
            // const lenWithoutSpace = this.form2.description.replace(/\s/g, "").length;
            if (lenDesc < 155) {
              return this.$Message.error("Must be more than 155 characters");
            }
            // if (lenWithSpace < 181) {
            //   return this.$Message.error(
            //     "Product description must be more than 181 characters w/ spaces"
            //   );
            // }
          } else {
            this.$Message.error("You still have something to fill out");
          }
          if (this.percent !== 0) {
            if (this.percent !== 100) {
              this.$Message.error("PERCENT COMPOSITION - MUST ADD UP TO 100%");
            } else {
              this.step++;
            }
          } else {
            this.step++;
          }
        } else {
          this.$Message.error("You still have something to fill out");
        }
      });
    },
    form1Validate() {
      this.$refs.form1.validate(valid => {
        if (valid) {
          this.step++;
        } else {
          this.$Message.error("You still have something to fill out");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}
.wide {
  width: 1100px;
  margin: 0 auto;
  .main-title {
    text-align: center;
    color: #000;
    margin: 20px 0;
  }
  .divide {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .item-tips {
    position: absolute;
    right: 0;
    font-size: 12px;
    .info {
      color: #999;
    }
    .link {
      color: @primary-color;
      text-decoration: underline;
    }
  }
  .form-box-group {
    .form-box-title {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 16px;
      color: #515a6e;
      line-height: 1;
      padding: 10px 12px 10px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .compose {
      display: inline-block;
      width: 100%;
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 6px;
    }
  }

  .form-btn {
    justify-content: space-between;
    display: flex;
    padding-bottom: 20px;
  }

  .upload {
    width: 100%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 20px;
    cursor: pointer;

    .upload-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .icon {
        color: @primary-color;
      }
    }
  }

  .images-box {
    width: 100%;
    overflow: hidden;
    display: flex;
    .item {
      width: 100px;
      height: 100px;
      background: #eee;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      margin-right: 10px;
      &:hover {
        .remove {
          opacity: 1;
        }
      }
      .remove {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        opacity: 0;
      }
      .image {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .add {
      width: 100px;
      height: 100px;
      background: #eee;
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .tips {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }
}

.cropper {
  width: 100%;
  height: 300px;
  border: solid 1px #ccc;
  margin-bottom: 20px;
}

.des-length {
  position: absolute;
  z-index: 999;
  right: 10px;
  bottom: 0px;
}

.form-title-xing {
  justify-content: initial !important;
}
.form-title-xing:before {
  content: "*";
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  font-size: 14px;
  color: #ed4014;
}
.library-images {
  .library-images-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height, or 144% */

    display: flex;
    align-items: center;

    /* Gray 3 */

    color: #828282;
    text-align: left;
    margin-bottom: 28px;
  }
  .library-images-items {
    display: flex;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
    .library-images-item {
      flex-basis: 25%;
      display: block;
      position: relative;
      margin-bottom: 4px;
      cursor: pointer;
      img {
        width: 266.67px;
        height: 150px;
        border-radius: 8px;
      }
      .mask {
        display: none;
      }
      .mask-active {
        background-color: rgba(61, 55, 82, 0.5);
        position: absolute;
        pointer-events: none;
        width: 266.66px;
        height: 150px;
        bottom: 6px;
        border-radius: 8px;
        left: 8px;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .library-or {
    display: flex;
    justify-content: center;
    margin: 32px 0 44px 0;
    .library-or-line {
      width: 492px;
      height: 0px;
      left: 5014px;
      top: 1418px;

      /* Secondary */

      border: 1px solid #f99d20;
    }
    .library-or-word {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      /* identical to box height, or 144% */

      display: flex;
      align-items: center;

      /* Secondary */

      color: #f99d20;
      position: relative;
      bottom: 12px;
      padding-left: 5px;

      padding-right: 5px;
    }
  }
}
</style>

<style lang="less">
.checkbox-box {
  padding: 0 10px;
}
.checkbox-box-input {
  width: initial;

  .ivu-input {
    border: 1px solid #ccc !important;
  }
}

.main {
  margin: 78px auto 0 auto;
  .back {
    .div-back {
      width: 300px;
      height: 40px;
      background: #ef7f21;
      border-radius: 5px;
      font-size: 20px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
      padding-top: 10px;
    }
  }

  .content-wide {
    /*box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;*/
    position: relative;
    top: 30px;
    margin-bottom: 50px;
    background: #fff;
    border-radius: 15px;
    .main-title {
      height: 60px;
      background: #fff;
      /*opacity: 0.9;*/
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      font-size: 24px;

      font-weight: 700;
      color: #292929;
      line-height: 18px;
      padding: 22px 0 0 40px;
      text-align: center;
    }
    .save-draft {
      width: 200px;
      height: 34px;
      background: linear-gradient(90deg, #f5a210, #ef8126);
      border-radius: 5px;
      font-size: 18px;

      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      padding-top: 8px;
      cursor: pointer;
    }
    .last-step {
      justify-content: flex-end;
    }
    .btns {
      display: flex;
      .back {
        cursor: pointer;
      }
      .add-to-catalog {
        cursor: pointer;
      }
    }
    .continue {
      width: 200px;
      height: 34px;
      background: #ffffff;
      border: 1px solid #ef7f21;
      border-radius: 5px;
      font-size: 18px;

      font-weight: 400;
      color: #ef7f21;
      line-height: 18px;
      text-align: center;
      padding-top: 8px;
      cursor: pointer;
    }
    .add-to-catalog {
      width: 240px;
      height: 34px;
      background: linear-gradient(90deg, #f5a210, #ef8126);
      border-radius: 5px;
      font-size: 18px;

      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      padding-top: 8px;
    }
    .back {
      width: 125px;
      height: 34px;
      background: #ffffff;
      border: 1px solid #ef7f21;
      border-radius: 5px;
      font-size: 18px;

      font-weight: 400;
      color: #ef7f21;
      line-height: 18px;
      text-align: center;
      /*padding-top:ppx;*/
    }
  }
}
.product-form {
  width: 1130px;
  margin: 0 auto;
  text-align: center;
}

.wide .form-box-group .form-box-title {
  font-size: 16px;
}
.top {
  .back-request {
    width: 220px;
    height: 40px;
    background: #ef7f21;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
    top: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    .pic {
      width: 8px;
      height: 10px;
      padding: 3px 0 0 35px;
      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 8px;
        left: 0px;
      }
    }

    .word {
      position: relative;
      top: 1px;
      font-size: 20px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
  }
}
.form-foot {
  /*background: #f5f7f9;*/
  width: 100%;
  height: 50px;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}
.product-form >>> .ivu-input {
  /*width:400px;*/
  height: 36px;
}
.product-form >>> .ivu-form-item-label {
  font-size: 16px;

  font-weight: 400;
  color: #292929;
  line-height: 18px;
}
.ivu-steps {
  width: 1150px;
  margin: 0 auto;
  justify-content: space-between;
}
.ivu-steps >>> .ivu-steps-title {
  font-size: 20px;

  font-weight: 500;
  color: #323538;
  line-height: 18px;
}
.ivu-steps >>> .ivu-steps-head-inner {
  position: relative;
  top: -3px;
}
.ivu-steps >>> .ivu-steps-item {
  padding-top: 3px;
  flex: auto;
}
.ivu-steps >>> .ivu-steps-head {
  background: none;
}
</style>
